import React from 'react';
import config from '../../config';
import { Modal, ModalBody, ModalFooter, Form, Alert, Button, Spinner } from 'react-bootstrap';

export default function DocumentListModal({ visible, setVisible, titulo }) {
    const [nombre, setNombre] = React.useState('');
    const [mensaje, setMensaje] = React.useState('');
    const [message, setMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if(nombre !== '' && mensaje !== '') {
            setMessage(null);
        }
    }, [nombre, mensaje]);

    const handleOk = () => {

        if (nombre !== '' && mensaje !== '') {
            setLoading(true);
            const body = 'Boletín: '+ titulo +' - ' + nombre + ' - ' + mensaje;
            const data = {
                KEYWORDS: body,
            };
            fetch(`${config.api}/prod/email`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setMessage(<Alert variant='success'>Mensaje enviado correctamente</Alert>)
                    setTimeout(() => {
                        setNombre('');
                        setMensaje('');
                        setMessage(null);
                        setVisible(false);
                    }, 2000);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setMessage(<Alert variant='danger'>Error al enviar el mensaje</Alert>)
                });
            
        } else {
            setMessage(<Alert variant='danger'>Por favor, llene todos los campos</Alert>)
        }

    }

    const handleCancel = () => {
        setVisible(false);
    }

    return (
        <Modal show={visible} onHide={handleCancel} aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalBody>
                <Form.Group className='mb-2'>
                    <Form.Label>Nombre y Apellido</Form.Label>
                    <Form.Control value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                </Form.Group>
                <Form.Group className='mb-2'>
                    <Form.Label>Mensaje</Form.Label>
                    <Form.Control value={mensaje} onChange={(e) => setMensaje(e.target.value)} as='textarea' rows={3} required />
                </Form.Group>
                {message}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleOk}>
                    {loading ? <><Spinner animation="border" size="sm" />&nbsp;</> : ''}
                    Enviar
                </Button>
                <Button onClick={handleCancel} variant='outline-light'>Cancelar</Button>
            </ModalFooter>
        </Modal>
    );

}