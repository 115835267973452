import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import LogoGris from '../../images/LogoGris.png';

export default function Carga() {
    return (
        <Container>
            <Row className='justify-content-md-center'>
                <Col className='mt-4 mb-4'>
                    <img src={LogoGris} alt="AYP LATAM" style={{ maxWidth: '150px' }} />
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={6} className='mt-2 text-start'>
                    <Placeholder as="p" animation="glow">
                        <Placeholder.Button xs={5} aria-hidden="true" />
                    </Placeholder>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={6} className='mt-2 text-start'>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={5} aria-hidden="true" size='lg' />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={10} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={8} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={10} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={8} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                    </Placeholder>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={6} className='mt-2 mb-4 text-start'>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={5} aria-hidden="true" size='lg' />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={10} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={8} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={10} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                        <Placeholder xs={8} aria-hidden="true" />
                        <Placeholder xs={12} aria-hidden="true" />
                    </Placeholder>
                </Col>
            </Row>
        </Container>
    );
}