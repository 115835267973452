// react module Navbar
import React from 'react';
import { Navbar, Form, Container, Button } from 'react-bootstrap';

const NavBar = ({ fontSize, setFontSize }) => {
    
    const moreFontSize = () => {
        setFontSize(fontSize + 0.1);
    }

    const lessFontSize = () => {
        setFontSize(fontSize - 0.1);
    }

    return (
        <Navbar bg="dark" className='justify-content-between' expand="xs" variant="dark" sticky='top'>
            <Container fluid>
                <Navbar.Brand href="#home">
                <img src="../logo.png" alt="logo" className="logo_ayp"/>
                </Navbar.Brand>
                
                <Form>
                    <Button variant='secondary' style={{fontSize:'0.8em'}} onClick={() => lessFontSize()}>
                        Aa
                    </Button>
                    &nbsp;
                    <Button variant='secondary' style={{fontSize:'1.2em'}} onClick={() => moreFontSize()}>
                        Aa
                    </Button>
                </Form>
            </Container>
        </Navbar>
    );
};

export { NavBar };