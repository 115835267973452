import React from 'react';
import config from './config';

import { NavBar } from './modules/navbar/Navbar';
import { Producto } from './modules/DocumentList/Producto';

import Carga from './modules/DocumentList/Carga';

// import bootstrap css
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [token, setToken] = React.useState("");
  const [uId, setUId] = React.useState("");
  const [producto_para_mostrar, setProductoParaMostrar] = React.useState(null);
  const [fontSize, setFontSize] = React.useState(1);
  const [clientData, setClientData] = React.useState(null);

  const getClientData = async (code, uid) => {
    let ip = '';
    let os = '';
    let browser = '';
    // Obtenemos la ip desde donde el cliente se conecta, también su sistema operativo y navegador, además la fecha y hora de la conexion
    await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => ip = data.ip);
    os = window.navigator.platform;
    browser = window.navigator.userAgent;
    let client_data = {
      'IP': ip,
        'OS': os,
        'TOKEN': code,
        'BROWSER': browser,
        'UID': uid
    }
    setClientData(client_data);
    return client_data;
  };

  const setMetaData = async () => {
    let data = clientData;

      // send post request via fetch
      fetch(`${config.api}/prod/metadata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(response => response.json())
      .then(data => {
        console.log("Tiempo registrado.")
      }).catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    // Obtener el token de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('token');
    const uid = urlParams.get('uid');
    setUId(uid);
    setToken(code);
    getClientData(code, uid);
  }, []);

  React.useEffect(() => {
    async function getProduct(){
      // getClientData in a promise or await
      let data = await getClientData(token, uId);

      // send post request via fetch
      fetch(`${config.api}/prod/view`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(response => response.json())
      .then(data => {
        if(data.status === "ok"){
          setProductoParaMostrar(data.data);
        }
      }).catch((error) => {
        console.log(error);
      });

    }

    if(token !== "") {
      // Obtener el producto
      getProduct();
    }
  }, [token]);

  // Cada 5 segundos enviaremos metadata al servidor para medir el % de rebote
  setInterval(() => {
    setMetaData();
  }, 5000);

  return (
    <div className="App">
      <NavBar setFontSize={setFontSize} fontSize={fontSize} />
      <div className="text-center">
        {producto_para_mostrar !== null ? 
        (
        <Producto 
          producto_para_mostrar={producto_para_mostrar} 
          setProductoParaMostrar={setProductoParaMostrar}
          fontSize={fontSize}
          setFontSize={setFontSize}
          />
        ):<>
          <Carga />
        </>}
      </div>
    </div>
  );
}

export default App;
