import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'moment';
import { Boletin } from './Boletin';

const Producto = ({ producto_para_mostrar, fontSize, setFontSize }) => {

    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    
    const handleDocumento = () => {
        let DOCUMENTO = producto_para_mostrar;
        /*if(DOCUMENTO.URL !== null && (DOCUMENTO.URL.includes('pdf') || DOCUMENTO.URL.includes('PDF'))){
            var url_path = DOCUMENTO.URL.replace(' ', '%20');
            return(
                <Container fluid>
                    <Row>
                        <Col>
                            <iframe src={`https://ayplatam.com/Scripts/ViewerJS/#${url_path}`} title={`${DOCUMENTO.NOMBRE}`} width="100%" height="600" allowFullScreen></iframe>
                        </Col>
                    </Row>
                </Container>
            );
        } */
        if(DOCUMENTO.TIPO_PRODUCTO_ID === 5) {
            return(
                <>
                    <Boletin Documento={DOCUMENTO} fontSize={fontSize} setFontSize={setFontSize} />
                </>
            );
        } /*else if(DOCUMENTO.TIPO_PRODUCTO_ID === 4) {
            return(
                <>
                    <EvaluacionInteres Documento={DOCUMENTO} />
                </>
            );
        } else if(DOCUMENTO.TIPO_PRODUCTO_ID === 8){
            return(
                <>
                    <Semblanza Documento={DOCUMENTO} />
                </>
            );
        }   */
        else {
            console.log(JSON.parse(DOCUMENTO.JAVASCRIPT));
            return(
                <>
                    <h1>Este archivo no es compatible con el visualizador, contacto con el administrador.</h1>
                </>
            );
        }
    }

    const Titulo = () => {
        let title = producto_para_mostrar.NOMBRE;
        let fecha_mostrar = "";

        if(producto_para_mostrar.FECHA != null && producto_para_mostrar.MES_EVALUACION != null){
            if(producto_para_mostrar.FECHA !== producto_para_mostrar.MES_EVALUACION){
                fecha_mostrar = Moment(producto_para_mostrar.FECHA).format('DD/MM/YYYY') + " - " + Moment(producto_para_mostrar.MES_EVALUACION).format('DD/MM/YYYY');
            } else {
                fecha_mostrar = Moment(producto_para_mostrar.FECHA).format('DD/MM/YYYY');
            }
        } else {
            fecha_mostrar = Moment(producto_para_mostrar.FECHA).format('DD/MM/YYYY');
        }

        if(producto_para_mostrar.SEMANA != null && producto_para_mostrar.SEMANA !== '') {
            return (<>
                    {title} - <small>{producto_para_mostrar.SEMANA}</small>
                </>);
        } else {
            return( <>
                {title} - <small>{fecha_mostrar}</small> 
            </>);
        }
    
    }

    return (
        <>
            {(producto_para_mostrar.TIPO_PRODUCTO_ID !== 5)?<Container fluid>
                <Row className="resultBelt">
                    <Col className='mb-3 mt-3' xs={12} lg={12}>
                        <h3 className='text-center'>
                            <Titulo/>
                        </h3>
                    </Col>
                </Row>
            </Container>:null}
            {handleDocumento()}
        </>
    );
};

export { Producto };