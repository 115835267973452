import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Modal from './Modal';

import './boletin.css';

const Boletin = ({ Documento, fontSize }) => {
    /* Creamos la clase cover cuya configuración debería ser similar a esta si es que URL no es vacío
        background-image: url(Documento.URL);
        background-size: cover;
    */
    // Declaramos la variable del estilo

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        // Reiniciamos el scroll en cuanto se carga la vista correspondiente al producto
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const handleCover = () => {
        switch(Documento.PAIS.NOMBRE) {
            case 'Guatemala': return ' guatemala';
            case 'Panamá': return ' panama';
            case 'Honduras': return ' honduras';
            case 'Costa Rica': return ' costarica';
            case 'El Salvador': return ' elsalvador';
            case 'Colombia': return ' colombia';
            case 'Belice': return ' belice';
            default: return ' default';
        };
    };

    const handleHeader = () => {
        if(Documento.URL !== null && Documento.URL !== undefined && Documento.URL !== ""){
            let cover = {
                backgroundImage: `url(${Documento.URL})`,
                backgroundSize: 'cover'
            }
            return (
                <div className='image-header' style={cover}>
                    <div className='image-header-space'>
                    </div> 
                    <div className='image-header-text'>
                    </div>
                </div>
            );
        } else {
            return(
                <div className={'image-header' + handleCover()}>
                    <div className='image-header-space'>
                    </div>
                    <div className='image-header-text'>
                    </div>
                </div>
            );
        }
    };

    const handleBoletion = () => {
            let contexto = Documento.RESUMEN;
            let impacto = Documento.CONTENIDO;

            return(
                <>
                    <Container fluid>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mt-2 mb-2'>
                                {handleHeader()}
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mb-3 text-start'>
                                <button className='btn btn-primary' onClick={() => setVisible(true)}>
                                    <FontAwesomeIcon icon={faEnvelope} style={{fontSize: fontSize +'em'}} />&nbsp;
                                    Solicitar más información
                                </button>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mt-b text-justify'>
                                <h5>{Documento.NOMBRE}</h5>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mb-2'>
                                <div className='text-justify' style={{fontSize: fontSize +'em'}} dangerouslySetInnerHTML={{__html: contexto}}></div>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mb-2 text-justify'>
                                <h5>Impacto en la Agenda de Intereses</h5>
                            </Col>
                        </Row>
                        <Row className='justify-content-md-center'>
                            <Col md={6} className='mb-2'>
                                <div className='text-justify' style={{fontSize: fontSize +'em'}} dangerouslySetInnerHTML={{__html: impacto}}></div>
                            </Col>
                        </Row>
                    </Container>
                    <Modal visible={visible} setVisible={setVisible} titulo={Documento.NOMBRE} />
                </>
            );
    };
    
    return (
        <>
            {handleBoletion()}
        </>
    );
};

export { Boletin };